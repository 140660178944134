<template>
  <b-card>
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Afficher</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="8" />
      <b-col
        md="2"
        sm="4"
        class="my-1 text-right"
      >
        <b-form-group class="mb-0">
          <b-button
            v-b-modal.modal-addProgramme
          >
            Nouveau programme
          </b-button>
        </b-form-group>

        <!-- Modal Upload -->
        <b-modal
          id="modal-addProgramme"
          ref="modal-addProgramme"
          cancel-variant="outline-secondary"
          ok-title="Ajouter"
          cancel-title="Annuler"
          centered
          title="Nouveau programme"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <b-form @submit.stop.prevent="handleSubmit">
            <b-alert
              :show="newProg.error === true"
              variant="danger"
            >
              <h4 class="alert-heading">
                Merci de remplir tout les champs
              </h4>
            </b-alert>
            <b-form-group>
              <label for="nom">Nom :</label>
              <b-form-input
                id="nom"
                v-model="newProg.nom"
                type="text"
                placeholder="Nom du document"
              />
            </b-form-group>

            <b-form-group>
              <label for="nom">Etat :</label>
              <b-form-input
                id="nom"
                v-model="newProg.etat"
                type="text"
                placeholder="Etat du programme"
              />
            </b-form-group>
          </b-form>
        </b-modal>

      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(etat)="data">
            <b-badge :variant="getVariant(data.value)">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(projet)="data">
            <router-link :to="{ name: 'admin-programmes-detail', params: { id: encrypt(data) }}">
              {{ data.item.projet }}
            </router-link>
          </template>

          <template #cell(emailing)="data">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              class="btn-icon"
              @click="emailing(data.item)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BButton, BModal, VBModal, BForm, BFormInput, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CryptoJS from 'crypto-js'

const config = require('@/../config')

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      fields: [
        {
          key: 'projet', label: 'Projet',
        },
        { key: 'etat', label: 'Etat' },
        { key: 'emailing', label: 'Emailing' },
      ],
      items: [],
      newProg: {
        nom: '',
        etat: '',
        error: false,
      },
    }
  },
  mounted() {
    this.list()
  },
  methods: {
    list() {
      this.$store.dispatch('projets/fetchAll', { page: this.currentPage, limit: this.perPage }).then(
        res => {
          this.items = res.projets
          this.totalRows = res.total
        },
      )
    },
    getVariant(value) {
      if (value.toLowerCase().includes('livraison')) {
        return 'primary'
      } if (value.toLowerCase().includes('termine')) {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    encrypt(data) {
      return CryptoJS.AES.encrypt(data.item.id.toString(), config.crypto_key).toString()
    },
    resetModal() {
      this.newProg.nom = ''
      this.newProg.etat = ''
      this.newProg.error = false
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      if (this.newProg.nom !== '' && this.newProg.etat !== '') {
        // Trigger submit handler
        this.handleSubmit()
      } else {
        this.newProg.error = true
      }
    },
    handleSubmit() {
      this.$store.dispatch('projets/insert', this.newProg)
        .then(() => {
          this.list()
          this.resetModal()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['modal-addProgramme'].toggle('#toggle-btn')
          })
        })
    },
    emailing(item) {
      this.$store.dispatch('projets/fetchOne', item.id).then(detail => {
        const emails = []
        detail.diffusionListes.map(data => {
          emails.push(data.associatedEmail)
        })

        detail.users.map(data => {
          emails.push(data.email)
          if (data.emailSecondaire) emails.push(data.emailSecondaire)
        })

        const date = new Date()

        let emlCont = 'To: \n'
        emlCont += `Subject: Communication ${detail.nom} ${date.getDate()}/${date.getMonth()}/${date.getFullYear()} \n`
        emlCont += 'X-Unsent: 1 \n'
        emlCont += 'bcc: '
        emails.map(el => {
          emlCont += `${el}; `
        })
        emlCont += '\n'
        emlCont += 'Content-Type: text/html;charset="utf-8"\n'
        emlCont += '\n'

        emlCont += '<!DOCTYPE html><html><head></head><body></body></html>'

        let textFile = null
        const data = new Blob([emlCont], { type: 'text/plain' })
        textFile = window.URL.createObjectURL(data)

        const a = document.createElement('a') // make a link in document
        const linkText = document.createTextNode('fileLink')
        a.appendChild(linkText)
        a.href = textFile
        a.id = 'fileLink'
        a.download = `emailing-${detail.id}.eml`
        a.style.visibility = 'hidden'

        document.body.appendChild(a)
        document.getElementById('fileLink').click()
        document.getElementById('fileLink').remove()
      })
    },
  },
}
</script>
